// Import Standard Tools
@import 'global/feature-tools';

body {
	margin: 0;
}

.edlio {
	.content {
		@import 'components/buttons';
		@import 'components/icons';
		@import 'components/links';
		@import 'components/notifications';
		@import 'components/overlays';
		@import 'components/cms/modals';
		@import 'components/forms';

		label {
			color: $soft-black;
		}

		font-size: 16px;
		display: flex;
		align-items: center;
		background: $grey-1;
		width: 100%;
		min-height: 100vh;
		position: relative;
		overflow: hidden;

		.login-card {
			width: 500px;
			max-width: 500px;
			padding: 3em;
			background: $white;
			margin: 0 auto;
			min-height: 500px;
			z-index: 2;
			position: relative;

			/* Material Card CSS */
			-webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
			box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			-webkit-box-pack: start;
			-ms-flex-pack: start;
			justify-content: flex-start;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			border-radius: 2px;
			overflow: hidden;

			@include max-screen-size(small) {
				height: 100vh;
			}

			uniform-notification {
				.message {
					font-weight: $bold;

					.sub {
						margin: 1em auto;
						line-height: 1.5em;
					}

					.reset-password-help {
						line-height: 1.5;
						margin-bottom: 1em;
						color: $black;
						font-weight: 400;

						a,
						div.bold {
							font-weight: $bold;
						}
					}
				}

				&+p {
					text-align: center;
				}
			}

			.help-message {
				text-align: center;
			}

			.forgot-password {
				display: none;
			}

			header {
				margin-bottom: 1.5em;

				.school-logo {
					width: 100%;
				}

				.school-title {
					width: 100%;
					color: #444444;
					text-align: center;
					margin: 0.5em auto 1.5em;
					line-height: 1.25em;
				}

				@include max-screen-size(small) {
					.school-title {
						line-height: 24px;
					}
				}
			}

			.spike-message {
				display: flex;
				align-items: center;
				margin-bottom: 1em;

				.logo {
					margin-right: 1.382em;
					padding: 0 .382em;
					background: #cb1a10;
					border-radius: 0.24063em;
				}

				svg {
					height: 3em;
					width: 3em;
					fill: $white;
				}

				span {
					font-size: 1.75rem;
					line-height: 1.125em;
					color: #cb1a10;
					left: .382rem;
				}

			}

			.card-body {
				.multifactor-form {
					margin-bottom: 1.5em;
				}

				input::-webkit-outer-spin-button,
				input::-webkit-inner-spin-button {
					/* display: none; <- Crashes Chrome on hover */
					-webkit-appearance: none;
					margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
				}

				input[type="number"] {
					-moz-appearance:textfield; /* Firefox */
				}

				input[type="number"],
				input[type="text"],
				input[type="password"] {
					width: 100%;
				}

				#username, #password, #twoFactorCode {
					&:focus {
						box-shadow: 0 0 1px 2px $blue;
					}
				}

				#twoFactorCode {
					margin-bottom: 0;
				}

				#username {
					margin-bottom: 1em;
				}

				#password,
				#password1 {
					margin: 0;
					padding-right: 3em;
				}

				.login-options {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin: 1em 0;
				}

				.g-recaptcha {
					display: flex;
					justify-content: center;
					margin: 2em 0;
				}

				.recaptcha-invisible {
					display: none;
				}

				.submit.button {
					background: $blue;
					color: $white;
					width: 100%;
					text-align: center;
					box-shadow: none;
					border-radius: 0.24063em;
					font-size: 1.3em;

					&.disabled {
						pointer-events: none;
						background: rgba($blue, .5);
					}

					&:focus {
						box-shadow: 0 0 0 2px #ffffff, 0 0 3px 5px #3a97f9;
					}
				}

				.descriptive-label {
					font-size: 14px;
					margin-bottom: 2em;
				}

				.text {
					font-size: 16px;
					margin-bottom: 4em;
				}
			}

			.error-text {
				color: $orange;
				font-weight: $bold;
				display: none;

				&.show {
					display: block;
				}
			}

			.forgot-password {
				header {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: flex-start;
					font-size: 2em;
					margin-bottom: 1em;

					a {
						font-size: .55em;
						flex: 1 0 100%;
					}

					span {
						margin-top: 15px;
						flex: 1 0 100%;
					}
				}

				.forgot-pwd-hint{
					margin-bottom: 1em;
				}
			}

			.invalid-login {
				edlio-notification {
					.message {
						line-height: 1.2;
						padding: 0.75rem;
						font-weight: $bold !important;

						.reset-password-help {
							margin-top: 1em;
							color: $black;
							font-weight: 400;

							a,
							div.bold {
								font-weight: $bold;
							}
						}
					}

					&+p {
						text-align: center;
					}
				}
			}

			.sso-sign-in {

				&.hide {
					display: none;
				}

				> div {
					text-align: center;
				}

				.sso-implement {
					margin-top: 4em;
				}

				.sso-button {
					display: block;
					text-align: center;
					display: inline-flex;
					padding: 0 1em;
					border-radius: .236em;
					line-height: 2.618em;
					height: 2.618em; /* IE10/11 */
					color: #fff;
					text-decoration: none;

					img {
						height: 3.5em;
					}

					svg {
						width: 1.618em;
						height: auto;
						margin-right: .618em;
						fill: #fff;
						vertical-align: top; /* IE10 */
					}

					&.office-365 {
						background: #125acd;

						&:hover {
							background: #196CF1;
						}

						&:active {
							background: #194FA6;
						}
					}

					&.canvas {
						background: #D64027;

						&:hover {
							background: #EA472C;
						}

						&:active {
							background: #C13B24;
						}
					}
				}

				.regular-sign-in {
					display: block;
					text-align: center;
					margin-top: 2em;
				}
			}

			.sso-super-admin-login {
				display: none;

				&.override-sso {
					display: block;

					header {
						margin-top: 1em;
					}
				}
			}

			.retry {
				display: block;
				width: 100%;
				text-align: center;
			}

			#foot_poweredby {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: auto;

				svg {
					width: 140px;
					height: 16px;
					fill: #535D67;
				}

			}

			.privacy_policy {
				display: flex;
				justify-content: center;
				margin-top: 1em;

				a {
					color: #1D9CD3 !important;
					font-family: 'Open Sans' !important;
					text-decoration: underline !important;
					font-size: 15px;
				}
			}

			.reset-form {
				.header {
					font-size: 2em;
				}

				p {
					margin: 1em 0;
				}

				#password1 {
					margin: 0;
				}

				label[for="password2"] {
					margin-top: 1em;
				}

				.password-validation {
					margin: 1em 0;

					.character-length,
					.string-matching,
					.not-common-used-matching,
					.uppercase-matching,
					.number-matching,
					.symbol-matching {
						svg {
							margin-right: 1em;

							&.fail {
								display: inline;
							}

							&.pass {
								display: none;
							}
						}

						&.pass {
							color: $green;

							svg {
								fill: $green;

								&.fail {
									display: none;
								}

								&.pass {
									display: inline;
								}
							}

						}
					}

					.character-length {
						margin-bottom: .25em;
					}
				}
			}

			.password-holder {
				position: relative;

				.password-show-option {
					position: absolute;
					right: .5em;
					bottom: .75em;

					label:before,
					label:after {
						display: none;
					}
				}

				.password-show-option:focus-within {
					outline: -webkit-focus-ring-color auto 1px;
				}
			}
			.support {
				text-align: center;
			}

			.submit-invisible {
				margin-bottom: 0;
			}

			.recaptcha-branding {
				text-align: right;
				margin: 0 0 1em 0;
				font-size: x-small;

				a {
					color: #444444;
				}
			}
		}

		.edlio-watermark-svg {
			fill: #EBEBEB;
			position: absolute;
			bottom: -0.5em;
			left: 0%;
			width: 22em;
			height: 19em;

			@include min-screen-size(large) {
				left: calc(50% - 37em);
				width: 28em;
			}
		}

	}

	.spin {
		@keyframes spin {
			from { transform: rotate(0deg); }
		  	to { transform: rotate(360deg); }
		}
  
		animation-name: spin;
		animation-duration: 3s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
	}
}

.grecaptcha-badge {
	visibility: hidden;
}
